@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    padding: 0;
    margin: 0;
    font-family: Inter;
}

.sec {
    width: 100%;
    display: flex;
}

.sec h1 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1em;
}

.sec p {
    font-size: 1.4rem;
    font-weight: 300;
    word-spacing: 2px;
    line-height: 1.4em;
    text-align: justify;
}


/* 1 */

.sec1 {
    padding-top: 11em;
    justify-content: center;
}

.about {
    width: 470px;
    margin-right: 5em;
    margin-top: 1em;
}

.about + img {
    height: 400px;
    width: 500px;
    box-shadow: 5px 5px 20px 5px grey;
    object-fit: cover;
}

/* 2 */

/* .sec2 {
    height: 28em;
    background-color: #DDE4EB;
    margin-bottom:22em;
    display: flex;
    justify-content: center;
    padding: 2em 0;
}

.parent {
    width: 440px;
    height: 100%;
    display:grid;
    align-items: center;
}

.medal, .ask, .where {
    display: flex;
    justify-content: center;
}

.askparent {
    margin: 0 4vw;
}

.parent h1 {
    font-size: 1.7rem;
    margin-bottom: .5em;
}

.parent p {
    font-size: 1.4rem;
    word-spacing: 0;
    line-height: initial;
}

.parent img {
    width: 5em;
    height: 5em;
    margin-top: 1em;
    margin-right: 2em;
} */

/* 3 */

.sec3 {
    width: 100%;
    justify-content: center;
    margin-top: 10em;
}

.trid {
    width: 480px;
    margin-left: 5em;
    margin-top: 2em;
}

.sec3 img {
    height: 400px;
    width: 500px;
    box-shadow: -5px 5px 20px 5px grey;
    object-fit: cover;
}

/* */

.sec2 {
    padding: 0;
    margin-top: 10em;
    padding-bottom: 22em;
}

/* CONTACT */

.contact {
    display: grid;
    width: 100%;
    height: 20em;
    background-image: url('/public/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    margin-top: -200px;
    margin-bottom: 300px;
    border-left: 0;
    border-right: 0;
}

.contact h1 {
    color: #fff;
    margin-top: 60px;
    text-shadow: 0px 0px 8px #00000099;
}

.btn {
    width: 350px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin-left: 50%;
    margin-bottom: 3em;
    transform: translateX(-50%);
    border: 5px solid #23313E;
    transition: all .2s ease;
}

.btn a {
    text-decoration: 0;
    color: #23313E;
    font-size: 1.5rem;
    font-weight: 600;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn:hover {
    background-color: #DDDDDD;
}

@media (max-width: 1500px) {
    .sec h1 {
        font-size: 2vw;
    }
    
    .sec p {
        font-size: 1.7vw;
    }
    
    .sec1 {
        padding-top: 11.8vw;
    }
    
    .about {
        width: 31.5vw;
    }
    
    .about + img {
        height: 27vw;
    }
    
    .sec2, .sec3, .sec4 {
        margin-top: 10.6vw;
    }
    
    .sec2 {
        height: 30vw;
        justify-content: space-between;
    }
    
    .parent {
        width: 29vw;
    }
    
    .parent img {
        width: 5.2vw;
        height: 5.2vw;
    }
    
    .parent h1 {
        font-size: 1.8vw;
    }
    
    .parent p {
        font-size: 1.6vw;
    }
    
    .askparent {
        margin: 0;
    }
    
    .medalparent {
        margin-left: 2vw;
    }
    
    .whereparent {
        margin-right: 2vw;
    }

    .sec3 img, .sec4 img {
        height: 27vw;
    }
    
    .trid {
        width: 32vw;
    }
    
    .txt {
        width: 31.8vw;
    }
    
    .contact {
        height: 21.4vw;
    }
    
    .btn {
        width: 23.5vw;
        height: 5.4vw;
    }
    
    .btn a {
        font-size: 1.6vw;
    }
}

@media (max-width: 950px) {

    .about h1, .trid h1 {
        font-size: 20px;
    }

    .about p, .trid p {
        font-size: 16px;
    }

    .about {
        width: 300px;
    }

    .trid {
        width: 300px;
    }

    .sec3 {
        margin-top: 100px;
    }

    .sec3 img {
        width: 415px;
        height: 256px;
    }

    .sec1 {
        padding-top: 110px;
    }

    .sec1 img {
        width: 340.5px;
        height: 256.5px;
    }

    .sec2 {
        flex-direction: column;
        height: fit-content;
        gap: 40px;
        margin-top: 100px;
    }

    .parent {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        margin: 0;
        gap: 5vw;
    }

    .enfant div * {
        font-size: 1.2rem;
    }

    .enfant img {
        width: 50px;
        height: 50px;
        margin-right: 2vw;
    }

    .enfant {
        width: 350px;
    }
 
    .contact {
        height: 210px;
        margin-bottom: 250px;
    }

    .contact h1 {
        font-size: 20px;
    }

    .btn {
        width: 233px;
        height: 61px;
    }

    .btn a {
        font-size: 16px;
    }
}

@media (max-width: 820px) {
    .enfant:nth-child(1) {
        padding-left: 10px;
    }

    .enfant:nth-child(2) {
        padding-right: 15px;
    }

    .parent h1 {
        font-size: 2.2vw;
    }

    .enfant p {
        font-size: 2vw;
    }

    .enfant img{
        width: 6.5vw;
        height: 6.5vw;
    }

    .sec1, .sec3 {
        justify-content: center;
        align-items: center;
    }

    .sec1 img, .sec3 img {
        margin-bottom: 50px;
    }

    .sec1 {
        flex-direction: column-reverse;
    }

    .sec3 {
        flex-direction: column;
    }

    .trid, .about {
        margin: 0;
        padding: 0;
    }
}

@media (max-width: 700px) {
    .about, .trid {
        width: 320px;
    }

    .about p, .trid p {
        font-size: 1.2rem;
    }

    .about h1 {
        font-size: 1.5rem;
        width: max-content;
    }

    .sec2 {
        padding-bottom: 4em;
    }
    
    .parent {
        flex-direction: column;
    }

    .enfant {
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0 !important;
    }

    .enfant div {
        align-items: center;
    }

    .enfant h1, .enfant p {
        text-align: center;
    }

    .enfant h1 {
        width: fit-content;
        margin: auto;
        padding: 15px 0;
        font-size: 1.4rem;
    }

    .enfant p {
        width: 300px;
        font-size: 1.2rem;
    }

    .enfant img {
        margin: 0;
        width: 70px;
        height: 70px;
    }
}
@media (max-width:485px) {
    .contact h1 {
        word-wrap: break-word;
        text-align: center;
    }
}

@media (max-width:425px) {
    .sec1 img, .sec3 img {
        width: 100vw;
        object-fit: cover;
    }
}
@media (max-width:375px) {
    .about, .trid {
        width: 85vw;
    }

    .about h1 {
        word-wrap: break-word;
        width: 100%;
        text-align: center;
    }

    .enfant {
        width: 100vw;
    }

    .enfant img {
        height: 18.67vw;
        width: 18.67vw;
    }

    .enfant h1 {
        font-size: 6vw;
    }

    .enfant p {
        font-size: 5.1vw;
    }
}
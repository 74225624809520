@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: Inter;
}

.form {
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #23313E;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    background-image: url(/public/exam\ \(1\).png);
    background-size: 70px;
    background-repeat: no-repeat;
    background-position: 18px;
    cursor: pointer;
    z-index: 21;
}

nav {
    position: fixed;
    width: 100%;
    height: 5em;
    background-color: #fff;
    z-index: 20;
}

.img1 {
    position: absolute;
    display: none;
    height: 55px;
    right: 1.5em;
    margin-top: 1px;
}

.img1 a {
    height: 100%;
    width: 100%;
}

.img1 img {
    height: 100%;
}

#check {
    display: none;
}

.nav_links {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    height: 100%;
    width: 100%;
    transform: translateX(-42px);
    margin: 0;
}

.nav_links li {
    display: inline-flex;
    height: 100%;
    width: 14%;
    margin: 0 10px;
}

.li {
    width: 125px;
    padding-left: 3em;
}

.li a {
    width: 120px;
}

.li a:hover {
    background: initial;
}

nav a {
    color: #23313E;
    font-weight: 500;
    font-size: 1.1rem;
    text-decoration: none;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s ease;
}

nav a:hover {
    background-color: #DDDDDD;
    color: #000;
}

.img {
    display: none;
    z-index: -1000;
}

.img img {
    display: none;
}

/* DROPDOWN */

.dropli {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.anchor {
    cursor: pointer;
    user-select: none;
}

a.anchor::before {
    content: '\25BC';
    position: absolute;
    left: initial;
    top: initial;
    margin-top: 2px;
    margin-left: 115px;
    width: 0;
}


.dropdown, .anchor {
    width: 90%;
    height: 100%;
}

.anchor:hover {
    background-color: initial;
}

.menu {
    width: fit-content;
    position: absolute;
    padding: 30px;
    align-items: center;
    justify-content: center;
    background-color: #23313E;
    z-index: 20;
    display: none;
    flex-direction: column;
    gap: 10px;
    left: 50%;
    transform: translateX(-47.6%);
}


.column {
    display: flex;
    gap: 10px;
}

.column a {
    margin: 0;
}

.column a {
    width: 250px;
    height: 90px;
    border: 3px solid #b9b9b9;
    font-size: 1.2rem;
    background-color: #fff;
    color: #000;
    text-align: center;
}

.column a:hover {
    background-color: #ececec;
    border-color: #ececec;
}

/* FOOTER */    

footer {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

.don, .madeby {
    position: absolute;
}

/* FIRST */

.don {
    height: 400px;
    width: 100%;
    background-color: #F1F1F1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.don > a {
    margin-bottom: 90px;
    margin-right: 10%;
}

.don img {
    width: 250px;
}

.col {
    height: 300px;
    margin-bottom: 30px;
    display: grid;
    justify-content: center;
}

.col1 {
    width: 380px;
    display: grid;
    justify-content: center;
}

.col2 {
    width: 280px;
    margin-left: 10%;
}

.col1 a, .col1 li {
    text-decoration: none;
    list-style: none;
}

.col1 a {
    transition: .2s all ease;
}

.col1 a:hover {
    text-decoration: underline;
}

.col1 a, .col2 p {
    color: #000;
    font-size: 1.3rem;
}

.col2 p {
    margin-bottom: 4px;
}

.col2 h1 {
    text-align: center;
}

.flemme {
    display: none;
    position: absolute;
}

.col2 span {
    margin-left: 5px;
}

.ul1 li {
    margin-bottom: 5px;
}

.footerimage2 {
    display: none;
    position: absolute;
}

/* SECOND */

.madeby {
    margin-top: 370px;
    height: 42px;
    width: 100%;
    background-color: #23313E;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

.madeby h3 {
    color: #fff;
    font-weight: 400;
    font-size: 1rem;
}

.madeby h3:nth-child(1) {
    margin-left: 15%;
}

.madeby h3:nth-child(2) {
    margin-right: 15%;
}

.madeby a {
    color: #fff;
}

@media (max-width:1470px) {
    .nav_links li:nth-child(3) {
        width: 206px;
    }

    .don {
        height: 350px;
    }

    .don img {
        width: 220px;
    }

    .col {
        margin-bottom: 0;
    }

    .col h1 {
        font-size: 1.4rem;
    }

    .col a, .col p {
        font-size: 1.2rem;
    }

    .ul1 li {
        padding-bottom: 6px;
    }

    .ul2 li {
        padding-bottom: 4px;
    }

    .prod {
        margin-bottom: 30px;
    }

    /* DROPDOWN */

    a.anchor::after {
        width: 206px;
    }
}

@media (max-width:1230px) {
    nav {
        height: 4.2em;
    }

    .nav_links a {
        font-size: 1rem;
    }

    .li img {
        width: 95px;
    }

    .don {
        height: 300px;
        padding-top: 2em;
    }

    .madeby {
        height: 40px;
    }

    /* DROPDOWN */

    a.anchor::after {
        top: 67.2px;
    }

    .menu {
        top: 67.2px;
        width: max-content;
        padding: 20px;
    }

    .column a {
        width: 200px;
        height: 72px;
    }

    .banderole {
        top: -.8em;
    }
}

@media (max-width:1130px) {

    .nav_links {
        display: flex;
        justify-content: right;
    }

    .nav_links li:nth-of-type(6) {
        margin-right: 0;
        padding-right: 0;
    }

    .nav_links li {
        width: 125px;
        padding: 0 1%;
    }

    li.img {
        display: none;
    }
    
    li.li {
        width: 95px;
    }

    .don > a {
        margin-right: 5%;
    }

    .don img {
        width: 200px;
    }
    .col2 {
        margin-left: 5%;
    }

    .col h1 {
        font-size: 1.3rem;
    }

    .col1 a, .col2 p {
        font-size: 1.1rem;
    }

    .prod {
        margin-bottom: 40px;
    }
}

@media (max-width:1050px) {
    
        .nav_links {
            justify-content: center;
        }

}

@media (max-width:1030px) {

    .li, .li a, .li a img {
        position: absolute;
        display: none;
    }
    
    .nav_links li:nth-child(5) {
        width: 125px;
    }


}

@media (max-width:950px) {
    nav {
        height: 3.5em;
    }
    
    .nav_links {
        position: fixed;
        right: 100vw;
        top: 0;
        display: grid;
        align-items: baseline;
        list-style: none;
        height: 100vh;
        width: 20em;
        background-color: #fff;
        box-shadow: 10px 0px 10px -5px rgba(0, 0, 0, 0.2);
        z-index: 1;
        left: -100%;
    }
    
    .nav_links li {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3em;
        width: 12em;
        margin: 0;
        border-bottom: 2px solid #dddddd;
    }

    .nav_links li:nth-child(1) {
        display: none;
        position: absolute;
    }

    .nav_links li:nth-child(2) {
        margin-top: 4em;
    }
    
    .nav_links li:nth-child(3), .nav_links li:nth-child(5) {
        width: 12em;
    }

    .nav_links li:nth-child(6) {
        margin-bottom: 3em;
    }

    .nav_links li:nth-child(7) {
        display: block;
        height: 8em;
        width: 13em;
        margin-bottom: 4em;
        z-index: 20;
        border: none;
    }

    .nav_links li:nth-child(7) img {
        display: block;
        
        width: 100%;
     }

    nav a {
        color: #000;
        font-weight: 500;
        font-size: 1.1rem;
        text-decoration: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: left;
        transition: all .2s ease;
        margin: 0;
    }
    
    nav a:hover {
        background: initial;
        color: initial;
    }

    #check:checked ~ .nav_links {
        left: 42px;
    }

    .menu-btn {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
        width: 60px;
        height: 60px;
        cursor: pointer;
        transition: all .5s ease-in-out;
        z-index: 100;
    }
    
    .menu-btn__burger {
        width: 40px;
        height: 4px;
        background: #000;
        transition: all .3s ease-in-out;
        position: relative;
    }
    
    .menu-btn__burger::before,
    .menu-btn__burger::after {
        content: '';
        position: absolute;
        left: 0;
        width: 40px;
        height: 4px;
        background: #000;
        transition: all .3s ease-in-out;
    }

    .menu-btn__burger::before {
        transform: translateY(-13px);
    }
    
    .menu-btn__burger::after {
        transform: translateY(13px);   
    }
    
    .menu-btn.open .menu-btn__burger {
        background: transparent;
    }
    .menu-btn.open .menu-btn__burger::before {
        transform: rotate(45deg);
    }
    .menu-btn.open .menu-btn__burger::after {
        transform: rotate(-45deg);
    }

    .img1 {
        display: block;
    }

    

    .don {
        display: grid;
        justify-content: center;
        height: 55em;
    }

    .footerimage1 {
        display: none;
    }

    .col1 {
        margin-top: 2em;
    }

    .col h1 {
        font-size: 1.5rem;
        text-align: center;
    }

    .col1 a, .col2 p {
        font-size: 1.2rem;
    }

    .col1 a {
        display: flex;
    }

    .col2 {
        margin: auto;
    }

    .footerimage2 {
        display: flex;
        justify-content: center;
        display: initial;
        position: initial;
        width: 192px;
        margin: auto;
        padding: 0;
        transform: translate(-50%, -15%);
    }

    .madeby {
        margin-top: 55em;
    }

    .madeby h3:nth-child(1) {
        margin-left: 2%;
    }

    .madeby h3:nth-child(2) {
        margin-right: 2%;
    }

    /* DROPDOWN */

    .menu {
        height: 30vh;
        width: 250px;
        padding-top: 65px;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        border: none;
        margin-top: 17.3vh;
        z-index: 100000000;
        overflow-y: auto;
        gap: 20px;
    }

    .column {
        width: 80%;
        justify-content: center;
        transform: translateY(10px);
        gap: 20px;
        flex-direction: column;
    }

    .column a {
        width: 100%;
        height: 60px;
        border: none;
        border-bottom: 2px solid #b9b9b9;
        margin: -10px 0;
        background: none;
        width: 100%;
        color: #fff;
        font-weight: 300;
        display: flex;
        justify-content: center;
    }

    .column:nth-child(1) a:nth-child(1) {
        margin-top: -50px;
    }

    .column a:hover {
        border: none;
        border-bottom: 2px solid #dddddd;
        background: none;
    }

    .dropdown, .anchor {
        width: 100%;
    }

    a.anchor::before {
        margin-top: -2px;
        margin-left: 180px;

    }

    a.anchor::after {
        display: none;
    }

    .banderole {
        top: -1.5em;
    }

    .ul1 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 500px) {
}

@media (max-width: 420px) {
    .col h1 {
        font-size: 1.3rem
    }

    .col1 a, .col2 p {
        font-size: 1.1rem;
    }
}

@media (max-width: 380px) {
    .nav_links {
        width: 100%;
    }

    .img {
        left: calc(50% + 7px);
        transform: translateX(-50%);
    }
    .nav_links li:nth-child(7) img{
        margin-left:50% ;
    }
    .col {
        width: 100%;
    } 

    .col2 h1 {
        margin-top: 1em;
    }
    
    .col1 ul {
        margin: 0;
        position: initial;
     }

     .col1 a {
        justify-content: center;
     }

     .col1 h1 {
        margin-bottom: 1em;
        text-align: center;
     }

     .col2 {
        margin-top: 5em;
     }

     .col2 h1 {
        display: flex;
        justify-content: center;
     }

     .footerimage2 {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: 50%;
        margin-top: -9%;
     }

     .madeby {
        margin-top: 59.5em;
     }

     .madeby h3{
        font-size: .85rem;
     }

     /* DROPDOWN */

     .menu {
        left: calc(50% + 2px);
        transform: translateX(-50%);
     }
}

.banderole {
    --bandheight: 30px;
    position: fixed;
    width: 100%;
    height: var(--bandheight);
    background-color: #23313E;
    transform: translateY(5em);
    overflow: hidden;
    z-index: 10;
    display: flex;
    box-shadow: 0 10px 10px #ffffff2f
}

.jvenm {
    position: fixed;
    width: fit-content;
    height: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: anim1 20s infinite;
    animation-timing-function: linear;
    white-space: nowrap;
}

.jvenm2 {
    position: fixed;
    width: fit-content;
    height: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: anim2 20s infinite;
    animation-timing-function: linear;
    animation-delay: 10s;
    white-space: nowrap;
    transform: translateX(-100%);
}

@keyframes anim1 {
    from {
        transform: translateX(-100%);
    }

    to {
        margin-left: 100%;
        transform: translateX(100%);
    }
}

@keyframes anim2 {
    from {}

    to {
        margin-left: 100%;
        transform: translateX(100%);
    }
}

.grouping p {
    text-align: center;
}

h3 {
    text-align: center;
}
@import url('https://fonts.googleapis.com/css2?family=Inria+Serif&family=Inter:wght@400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    text-align: center;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
}

* {
    padding: 0;
    margin: 0;
    font-family: inter;
}

#abs {
    position: absolute;
}

.trente {
    background-position: 30% 70%;
}

.dix {
    background-position: 30% 70%;
}
#un{
    background-image: url('/public/ver-per.jpg');
}
#deux{
    background-image: url('/public/maco.jpg');
}
#trois{
    background-image: url('/public/pergo.jpg');
}
#quatre{
    background-image: url('/public/extensionhabitat.jpg');
    background-position: 60%;
}

#cinq {
    background-image: url('/public/chassis.png');
    background-position: 60% 0;
}

#six {
    background-image: url('/public/maisonencours.jpg');
    background-position: 45% ;
}

#sept {
    background-image: url('/public/volets.jpg');
}



.wrap-ddd {

    width: 800px;
    height: 700px;
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 7em;
    margin-bottom: 3em;
}

.imgddd-haut {
    left: 0;
    top: 0;
    height: 500px;
}

.h4-ddd {
    right: 5%;
    top: 10%;
    font-family: Inter;
    font-weight: 400;
    font-size: 1.5rem;
    border-bottom: 2px solid black;
    padding-bottom: .5em;
}

.imgddd-bas {
    bottom: 0;
    right: 0;
    height: 300px;
}

.background_home {
    background: url('/public/image\ 15bg_eclaire.png');
    background-size: cover;
    background-position: 30%;
    width: 100%;
    height: 90vh;
    transform: translateY(5em);
}

.back-img {
    visibility: hidden;
    width: 100%;

}

.borer-wrap {
    border: 5px solid black;
    background-color: red;
}

.br-quote {
    display: none;
}

.header {
    background-color: hsla(0, 0%, 65%, 0.678);

   
    width: fit-content;
    height: 310px;
    position: absolute;
    margin-left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;

    backdrop-filter: blur(2px);
    margin-top: 50vh;
    transform: translate(-50%, -70%);
    padding: 0;
}

h1 {
    font-family: Inter;
    font-weight: 600;
}

.separe-titlel {
    width: 80%;
    height: 5px;
    background-color: white;
    /* margin-left: 50%; */
    /* transform: translate(-50%); */
    box-shadow: 2px 2px 20px rgb(0, 0, 0);
}

.nom {
    text-shadow: 1px 1px 30px rgba(0, 0, 0, 0.705);
    font-size: 6rem;
    color: white;
    /* margin-bottom: 0; */
    /* margin-top: 10px; */
}

.slogan {
    text-shadow: 1px 1px 30px rgba(0, 0, 0, 0.321);
    /* margin-top: 0.1em; */
    font-size: 3rem;
    color: white;
}

.rest {

    height: auto;


}

.activite {
    margin: 50px auto 0;
    display: flex;
    gap: 1.8vw;
    width: fit-content;
}

.produit {
    height: 28vw;
    width: 7vw;
    background-color: rgb(58, 58, 58);
    display: inline-grid;
    grid-template-columns: auto;
    grid-template-rows: 75% 25%;
    border-radius: 3.5vw;
    z-index: 1;
    transition: .4s all ease-in-out;
    margin-top: 80px;
    position: relative;
    overflow-x: hidden;
    background-size: cover;
    border: 2px solid #fff;
    box-shadow: 0 0 0px 2px #23313E;
}
.produit{
    overflow: hidden;
    padding-bottom: 1em;
}

.div-expli {
    opacity: 0;
    transition: .4s all ease-in-out;
    text-align:left;
    margin-left: 5%;
    display: block;
    width: 20vw;
    grid-row-start: 2;
    grid-row-end: 3;
}

.div-expli h3{
    text-align: left;
}

.div-expli p{
    font-size: 1rem;
}

.produit:hover {
    width: 28vw;
}

.produit:hover > .div-expli {
    opacity: 1;
}

.li img {
    width: 95px;
}
#h3-expli {
    font-family: Inter;
    color: white;
    text-shadow: 1px 1px 30px black;

}

#h3-expli {
    margin-bottom: 1em;
    margin-top: .5em;
}

#p-expli {
    text-shadow: 2px 2px 30px black;
    font-family: Inter;
    color: rgb(255, 255, 255);

}

a {
    text-decoration: none;
    color: black;

}


.quote {
    padding-top: 5em;
    height: 20vh;
}

h2 {
    font-size: 3rem;
    font-weight: 400;
    font-family: 'Inria Serif';

}

.exp {
    margin-top: 6%;
    border-bottom: 2px solid black;
}

.quote-marc {
    margin-bottom: .5em;
}

.name-quote {
    font-size: 1.5rem;
    font-weight: 400;
    font-family: 'Inria Serif';
    margin-left: 40vw;
}

#quote-marc1 {
    margin-right: .5em;
}

#quote-marc2 {
    margin-left: .5em;
}

.propos {
    height: 800px;
    position: relative;
}

.text-propos-home {
    height: fit-content;
    padding-bottom: 2em;
    width: 50vw;
    background-color:  hsl(136, 59%, 41%);
    color: white;
    margin-left: 13%;
    text-align: left;
    margin-top: 5%;
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    border: .3em solid rgb(235, 235, 235);
}

.h4-propos {
    font-size: 2.5rem;
    font-family: Inter;
    font-weight: 600;
}

.p-propos {
    font-size: 1.5rem;
    font-weight: 300;
    font-family: Inter;
}

.img-propos {
    height: 40%;
    position: absolute;
    margin-top: -2%;
    margin-left: 0%;
}

.clients {
    position: absolute;
    right: 10%;
    top: 10%;
    border: 1px solid black;
    display: none;
}

.client-compte {
    display: inline-block;
    font-size: 3rem;
    font-family: Inter;
}

.client-text {
    display: inline-block;
    font-size: 1.2rem;
    text-align: left;


}

.ans-p {
    margin-top: 0;
    margin-bottom: 0;
    font-family: Inter;
}

.exp-p {
    margin-top: 0;
    font-family: Inter;
}

.exp {
    position: absolute;
    left: 25%;
}

.exp-compte {
    display: inline-block;
    font-size: 3rem;
    font-family: Inter;

}

.exp-text {
    display: inline-block;
    text-align: left;
    font-size: 1.2rem;
}

.client-p {
    margin-top: 0;
    font-family: Inter;
    margin-bottom: 0;
}

.satisfait-p {
    margin-top: 0;
    font-family: Inter;
}



.information {
    width: 100%;
    padding-top: 4em;
    height: 400px;
    visibility: hidden;
    opacity: 0;
    user-select: none;
}

.h3-infor {
    font-size: 2rem;
}

.p-infor {
    font-family: Inter;
    font-weight: 400;
    font-size: 1.2rem;
    text-align: justify;
    width: 40%;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 1em;
}

@media (max-width:1410px) {
    .activite {

        padding-top: none;
        margin-top: none;
    }

}

@media (max-width:1291px) {
    .produit {
        overflow: hidden;
    }
}

@media (max-width:970px) {
    .header {
        padding-top: 2em;
        padding-bottom: 2em;
        height: fit-content;
    }

    .background {
        border: none;
        width: 100%;
    }

    .activite {
        padding-top: 3em;
        margin-top: none;
    }

    .propos {
        transform: translateY(-55px);
    }
    
    .nom {
        font-size: 4rem;
    }

    .slogan {
        font-size: 2rem;
    }
}

@media (max-width:970px) {
   
    .p-infor {
        width: 90vw;
    }

   

    .header {
        height: fit-content;
    }

  
    .activite {
        height: auto;
        padding-top: 0;
        margin: 100px auto;
        transform: translate(0);
    }

    .produit {
        margin: 0;
        width: 8vw;
        height: 30vw;
        border-radius: 4vw;
    }

    #h3-expli {
     
        color: white;
        text-shadow: 2px 2px 30px black;
        font-size: 1.5rem;
    }

    .background {
        background: url('/public/background.png');
        background-size: 300%;
        background-position: 40% 70%;


        margin-top: 5em;
        height: 85vh;


        position: absolute;
    }

    .nom {
        font-size: 4rem;
    }

    .slogan {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.6rem;
    }

    .text-propos-home {
        margin: 0;
        width: 90vw;
        padding-left: 2em;
        padding-bottom: 4em;
    }

    .img-propos {
        margin-left: -45%;
        size: 50vw;
    }

    .clients {
        display: none;
    }

    .exp {
        display: none;
    }
}

@media (max-width:827px) {
    .wrap-ddd {

        text-align: center;
        width: 100%;
    }

    .h4-ddd {
        right: 0;
        left: 0;
    }

    .imgddd-bas {
        right: 0;
        left: 0;

    }

    .imgddd-haut {
        right: 0;
        left: 0;

    }
}

@media (max-width: 720px) {
    .background {
        background-size: 320%;
    }

    .img-propos {
        height: 35%;
    }
}

@media (max-width:700px) {
    .header {
        height: fit-content;
    }
    .nom {
        font-size: 4rem;
    }

    .slogan {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.6rem;
    }
}

@media (max-width:675px) {
    .header {
        height: fit-content;
    }

    .nom {
        font-size: 4rem;
    }

    .slogan {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.6rem;
    }
}

@media (max-width:625px) {
    .header {
        height: fit-content;    
    }

   
    .nom {
        font-size: 4rem;
    }

    .slogan {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.6rem;
    }

    .img-propos {
        height: 30%;
        margin-top: -5%;
    }

    .quote {
        margin-top: 3em;
    }
}

@media (max-width: 590px) {
    h2 {
        font-size: 1.4rem;
    }

    .name-quote {
        font-size: 1.1rem;
    }

  
}

@media (max-width:570px) {
    .header {
        height: fit-content;
    }
    .nom {
        font-size: 4rem;
    }

    .slogan {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.6rem;
    }
}

@media (max-width:540px) {
    .header {
        height: fit-content;
    }

    

  

    .nom {
        font-size: 4rem;
    }

    .slogan {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.6rem;
    }

    .img-propos {
        margin-left: -50%;
        margin-top: 0%;
    }

    .br-quote {
        display: block;
    }
}

@media (max-width:525px) {
    .header {
        height: fit-content;
    }

    #h3-expli {
        color: white;
        text-shadow: 2px 2px 30px black;
        font-size: 1.1rem;
    }

    h2 {
        margin-top: 0em;
    }

   

    .nom {
        font-size: 3rem;
    }

    .slogan {
        font-size: 1.5rem;
    }

    h2 {
        font-size: 1.6rem;
    }

    .quote {
        margin-top: 4em;
    }
}



@media (max-width:490px) {
    .header {
        height: fit-content;
    }

    .nom {
        font-size: 3rem;
    }

    .slogan {
        font-size: 1.5rem;
    }

    h2 {
        font-size: 1.6rem;
    }
}


@media (max-width:467px) {
    .header {
        height: fit-content;
    }

    .nom {
        font-size: 3rem;
    }

    .slogan {
        font-size: 1.5rem;
    }

    h2 {
        font-size: 1.6rem;
    }
}

@media (max-width:427px) {
    #quote-marc2 {
        display: none;
    }

    .header {
        height: fit-content;
    }

    .nom {
        font-size: 3rem;
    }

    .slogan {
        font-size: 1.5rem;
    }

    h2 {
        font-size: 1.6rem;
    }

    .text-propos-home {
        width: 90vw;

    }

    .img-propos {
        width: 100%;
        margin-top: 3%;

    }

    .quote {
        margin-top: 2em;
    }

    .p-propos {
        font-size: 1.3rem;
    }
}


@media (max-width:400px) {
    .header {
        height: 125px;
    }
   
    .nom {
        font-size: 3rem;
    }

    .slogan {
        font-size: 1.5rem;
    }

    h2 {
        font-size: 1.6rem;
    }
}

@media (max-width:380px) {
    .header {
        height: 95px;
    }

    .nom {
        font-size: 2.2rem;
    }

    .slogan {
        font-size: 1.2rem;
    }

    h2 {
        font-size: 1.6rem;
        text-align: center;
    }

    .quote {
        margin-top: -4em;
        text-align: center;
    }

    #quote-marc2 {
        display: none;
    }

    .img-propos {
        height: 20%;
    }
}
@media (max-width:1101px) {
    .propos{
        height: 960px;
    }
    .img-propos{
        height: 30%;
    }
}
@media (max-width:950px) {
    .propos{
        height: 800px;
    }
    footer{
        margin-top:350px;
    }
}
@media (max-width:720px) {
    .activite {
        display: flex;
        flex-direction: column;
    }

    .produit{
        width: 90vw;
        margin: 10px 0;
        grid-template-rows: 20% 80%;
    }

    .produit:hover {
        width: 90vw;
    }

    .produit .div-expli {
        opacity: 1;
    }

    .div-expli{
        width: 70vw;
    }
    #p-expli{
        font-size: 1.2rem;
    }
    #h3-expli{
        font-size: 1.7rem;
    }
    .text-propos-home{
        width: 70vw;
        
    }
    .p-propos{
        font-size: 1.5rem;
    }
}
@media (max-width:580px) {

    .propos{
        height: 960px;
    }
   
   
}
@media (max-width:513px) {

    .produit{
        width: 90vw;
        height: 40vh;
    }
   
}
@media (max-width:477px) {

    footer{
        margin-top: 500px;
    }
    .propos{
        height: 1000px;
    }
   
   
}
@media (max-width:397px) {

}
@media (max-width:333px) {

   
}
@media (max-width:496px) {
    .img-propos{
        height: auto;
        width: 90vw;

    }
}

@media (max-width: 439px) {
    .h3-expli_even {
        margin-bottom: 5px !important;
    }
}
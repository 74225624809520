@import url('https://fonts.googleapis.com/css2?family=Inria+Serif&family=Inter:wght@400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
:root{
    --card_width: 40vw;
    --card_border_radius: 0px;
    --row_increment:10px;
    --card_small:36;
    --card_medium:43;
    --card_large:55;
}
* {
    padding: 0;
    margin: 0;
    font-family: inter;
}

body{
    margin: 0;
    padding: 0;
    
}
.background-rea h1{
    font-size: 4rem;
    color: white;
    text-shadow: 1px 1px 30px black;
}

.text{
    margin-top: 8em;
    width: 70vw;
    margin-left: 50vw;
    transform: translateX(-50%);
    margin-bottom: 5em;
}
.pin_container{

    margin: 0;
    margin-top: 2em;
    padding: 0;
    width: 100%;
    
    left: 50%;
    

    display: grid;
    grid-template-columns: repeat(auto-fill, var(--card_width));
    grid-auto-rows: var(--row_increment);
    justify-content: center;
    

    
}
#width-show{
    width: 500px;
}
.card{
    padding: 0;
    margin: 15px 10px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: var(--card_border_radius);
    box-shadow: 1px 1px 10px black;

}
.card:hover{
    cursor: pointer;
}

.card_small{
    grid-row-end: span var(--card_small);
}
.card_medium{
    grid-row-end: span var(--card_medium);
}
.card_large{
    grid-row-end: span var(--card_large);
}


.background-rea{
    background: url('/public/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    transform: translateY(5em);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.show{
    
    position: fixed;
    left: 50%;
    top: 50%;
    margin-top: 2em;
    transform: translate(-50%, -50%) ;
    z-index: 200;
    
}
h2{
    font-family:inter ;
}
.blur{
    position: fixed;
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    top: 0;
    backdrop-filter: blur(5px);
   
    display: none;
}


@media (max-width:542px) {
    #width-show{
        width: 300px;
    }
  
    :root{
        --card_width: 40vw;
        --card_border_radius: 0px;
        --row_increment:10px;
        --card_small:26;
        --card_medium:33;
        --card_large:45;
    }
}

@media (max-width:434px) {
  
    h1{
        top: 36%;
        font-size: 3rem;
    }

}
@media (max-width:425px) {
 
    h1{
        top: 36%;
        font-size: 3rem;
    }
    :root{
        --card_width: 40vw;
        --card_border_radius: 0px;
        --row_increment:10px;
        --card_small:16;
        --card_medium:23;
        --card_large:35;
    }
  
}
@media (max-width:320px) {
    :root{
        --card_width: 40vw;
        --card_border_radius: 0px;
        --row_increment:10px;
        --card_small:16;
        --card_medium:23;
        --card_large:28;
    }

}
.footerrea{
    margin-top: 300px;
}
@media (max-width:950px) {
    
    .footerrea{
        margin-top: 500px;
    }
}
@media (max-width:390px) {
    
    .background-rea h1{
        font-size: 2rem;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    padding: 0;
    margin: 0;
    font-family: inter;
}

/* BACKGROUND */

.section1 {
    padding-top: 5em;
    height: 30em;
    background: url('/public/maison_campagne.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.partenaires img {
    width: 200px;
    height: 150px;
    object-fit: cover;
}

.partenaire1 img {
    width: 220px;
    height: 140px;
}

/* SECTION 2 */

.section2 {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 350px;
}

/* FORMULAIRE */

.input {
    height: 1000px;
    width: 40vw;
    display: grid;
    background-color: #23313E;
    transform: translateY(-100px);
}

.input h1 {
    font-size: 1.5rem;
    color: #fff;
    margin: auto;
    padding-top: 20px;
}

.textContact {
    background: none;
    border: none;
    border-bottom: 3px solid #fff;
    font-size: 1.4rem;
    font-weight: 600;
    color: #fff;
    margin: 10px auto;
    width: 80%;
    outline: none;
}

.textContact::placeholder {
    color: #CCCCCC;
}

textarea {
    resize: none;
    width: 77.5%;
    margin: 30px auto 0;
    background: none;
    border: 3px solid #fff;
    font-weight: 600;
    font-size: 1.4rem;
    color: #fff;
    outline: none;
    padding-top: 5px;
    padding-left: 10px;
}

textarea::placeholder {
    position: absolute;
    top: 5px;
    left: 10px;
    color: #bebebe;
}

form {
    width: 80%;
    margin-left: 10%;
    margin: 20px auto 40px;
    display: flex;
    justify-content: left;
    align-items: center;
    overflow: hidden;
}

form::after {
    content: '\25BC';
    position: absolute;
    margin-left: 200px;
    cursor: pointer;
    pointer-events: none;
    color: #fff;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    height: 100%;
    width: 220px;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 500;
    cursor: pointer;
}

select::-ms-expand {
    display: none;
}

option {
    color: #000;
}

.checkbox {
    width: 80%;
    height: 35px;
    margin: 0 auto;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.checkbox__input {
    display: none;
}

.checkbox__box {
    height: 1.25em;
    width: 1.25em;
    border: 2px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    user-select: none;
}

.checkbox__box::after {
    position: absolute;
    content: '';
    height: 30px;
    width: 2px;
    background-color: #fff;
    transform: rotate(45deg);
    opacity: 0;
    transition: all .1s ease;
}

.checkbox__box::before {
    position: absolute;
    content: '';
    height: 30px;
    width: 2px;
    background-color: #fff;
    transform: rotate(-45deg);
    opacity: 0;
    transition: all .1s ease;
}

.checkbox__input:checked + .checkbox__box::after, .checkbox__input:checked + .checkbox__box::before {
    opacity: 1;
}

.checkbox h2 {
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: .5px;
    color: #fff;
    user-select: none;
    text-align: left;
    margin-left: 20px;
    margin-top: 10px;
}

.send {
    width: 40%;
    height: 3.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30%;
    border: 3px solid #fff;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: .4s ease;
}

.send::after {
    content: '✓';
    font-size: 2rem;
    position: absolute;

    display: none;
    /* display: flex; */

    justify-content: center;
    align-items: center;
    height: 80px;
    width: 84px;
    padding-bottom: 4px;
    background-color: #172029;
    border-radius: 50%;
    border: 3px solid #fff;
}

.send:hover {
    background-color: #172029;
}

.coord {
    width: fit-content;
    display: flex;
    justify-content: center;
    gap: 10%;
    margin-top: 100px;
}

.nc, .nss {
    text-align: left;
}

.coord h1 {
    margin-bottom: 1.2em;
}

.break {
    display: none;
}

.coord h1 {
    font-size: 1.9rem;
}

.coord p {
    font-size: 1.4rem;
    font-weight: 600;
}

.nc span {
    font-weight: 400;
    margin-left: 10px;
}

.p1 {
    padding-bottom: 1.2em;
    font-size: 1.5rem !important;
}

.p2 {
    padding-bottom: 1em;
}

.p3 {
    margin-left: 10px;
    padding-bottom: 2em;
    font-weight: 500 !important;
}

span.direct, span.ct, span.ctc {
    margin-left: 0px;
    text-decoration: underline;
}   

.nss h2 {
    width: fit-content;
    font-size: 1.4rem;
    font-weight: 600;
    color: #8F6F51;
    text-decoration: underline;
    margin-bottom: 5px;
}

.nss p {
    font-weight: 400;
    margin-left: 10px;
    margin-bottom: 40px;
}

.nss {
    width: 320px;
}

@media (max-width:1340px) {
    .input {
        transform: translateY(-50px);
    }

    .coord {
        flex-direction: column;
        gap: 60px;
        margin-top: -250px;
    }
    
    .coord h1 {
        font-size: 1.7rem;
        text-align: center;
    }

    .nc {
        width: max-content;
        margin: 0 auto;
    }

    .nss {
        width: 100%;
    }

    .telephone {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto;
        gap: 100px;
    }

    .partenaires {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        gap: 10px 40px;
        width: max-content;
    }

    .partenaire {
        width: max-content;
    }
}

@media (max-width:1230px) {
    .section2 {
        justify-content: space-between;
    }

    .input {
        width: 47vw;
    }

    .coord {
        margin: -250px auto 0;
    }
/* 
    .nc * {
        text-align: center;
    }

    .nss {
        justify-content: center;
    }

    .nss * {
        text-align: center;
    }

    .nss h2 {
        margin-left: 50%;
        transform: translate(-50%);
    } */
}

@media (max-width:1050px) {

    .section1 {
        height: 320px;
    }

    .input input {
        height: 40px;
    }

    .input textarea, .input select, .input input {
        font-size: 1.2rem;
    }

    .coord {
        margin-top: -250px;
    }

    .coord h1 {
        font-size: 1.7rem;
    }
    
    .coord p {
        font-size: 1.2rem;
    }
    
    .nss h2 {
        font-size: 1.3rem;
    }
}

@media (max-width:950px) {

    .section2 {
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 300px;
        gap: 50px;
    }

    .input {
        margin-top: 50px;
        width: 60vw;
    }

    .coord {
        margin-top: 36px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .coord * {
        text-align: center;
    }

    .nc , .nss {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .nss h2 {
        padding-bottom: 1.5em;
    }
    
}

@media (max-width: 600px) {
    .input {
        width: calc(100% - 40px);
    }
}

@media (max-width: 550px) {
    .section1 {
        height: 20em;
    }
    
    .input h1 {
        font-size: 4.37vw;
    }
}

@media (max-width: 488px) {
    .partenaires {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        margin: 0 auto;
    }
}

@media (max-width: 476px) {
    .section2 {
        margin-bottom: 200px;
    }
}

@media (max-width: 420px) {
    .partenaires img {
        width: 180px;
        height: 135px;
    }

    .partenaire1 img {
        height: 118px;
    }

    .input h1 {
        font-size: 4.6vw;
    }

    .input input, .input textarea {
        font-size: 1.1rem;
    }

    .checkbox__box {
        width: 1em;
        height: 1em;
    }

    .checkbox__box::after, .checkbox__box::before {
        height: 1.5em;
    }

    .checkbox__box + h2 {
        font-size: .8rem;
    }

    .input option {
        font-size: 1.1rem;
    }

    .send {
        height: 2.5em;
        font-size: 1.2rem;
    }

    .coord h1 {
        font-size: 1.5rem;
    }

    .telephone {
        gap: 10vw;
    }
}

@media (max-width: 380px) {
    .input {
        width: calc(100% - 20px);
    }

    .coord h1 {
        display: flex;
        justify-content: center;
    }

    .nc, .nss {
        display: grid;
        justify-content: center;
    }

    .telephone {
        grid-template-columns: auto;
    }
}
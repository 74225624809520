@import url('https://fonts.googleapis.com/css2?family=Inria+Serif&family=Inter:wght@400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    font-family: Inter;
    margin: 0;
    padding: 0;
   
}
body{
    text-align: center;
    margin: 0;
    padding: 0;
}

.backgroundprod{
    background-size: cover !important;
    width: 100%;
    height: 50vh;
    box-shadow: -0px  -10px  30px inset rgb(4, 4, 4);
    transform: translateY(5em);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4em;
}

.verandas_bg {
    background: url('/public/background.png');
}

.grosoeuvres_bg {
    background: url('/public/maco.jpg');
    background-position: 0 30%;
}

.chassis_bg {
    background: url('/public/chassis.png');
}

.volets_bg {
    background: url('/public/volets.jpg');
}

.pergolas_bg {
    background: url('/public/pergo.jpg');
    background-position: 0 10%;
}

.extensions_bg {
    background: url('/public/extensionhabitat.jpg');
    background-position: 0 20%;
}

.renovations_bg {
    background: url('/public/maisonencours.jpg');
    background-position: 0 30%;
}

.backgroundprod h1{
    text-shadow: 1px 1px 30px black;
    color: white;
    font-weight: 700;
    font-size: 4rem;
    margin-bottom: 4vh;
}


.verandas_img{
    background: url('/public/ver-per.jpg') ;
}

.grosoeuvres_img {
    background: url('/public/maconnerietoit.jpg');
}

.chassis_img {
    background: url('/public/chassis2.png');
}

.volets_img {
    background: url('/public/volet.jpg');
}

.pergolas_img {
    background: url('/public/pergola_travail.jpg');
}

.extensions_img {
    background: url('/public/1728987218628.jpg');
}

.renovations_img {
    background: url('/public/1728987218736.jpg');
}

.maconne{
    padding-top: 80px;
    height: fit-content;
}

.expli-ver{
    
    padding-right: 2em;
    display: inline-block;
    width: 40%;
    text-align: left;
    height: 100%;
    margin: auto;
    
    transform: translateY(-8em);
}
.filter h2{
    margin-top: 0;
    margin-bottom: 1em;
}
.filter p{
    font-size: 1.3rem;
    text-align: justify;

}
.expli-img{
    display: inline-block;
    width: 50vw;
    box-shadow: 1px 1px 30px black;
    
}
.grosse{
    padding-top: 80px;
    height: fit-content;
    vertical-align:middle;
    padding-bottom: 80px;
}

.expli-per{
  
    padding-left: 2em;
    display: inline-block;
    width: 40%;
    text-align: left;
    height:fit-content;
    margin: auto;
    
    transform: translateY(-10em);
    

}
.expli-per-img{
    height:  35em;
    width:  35em;
    background-size: cover;
    display: inline-block;
    box-shadow: 1px 1px 30px black;

}
.line{
    width: 70vw;
    border: 1px solid rgba(83, 83, 83, 0.584);
    margin-left: 50vw;
    transform: translate(-50%);
    margin-top: 5em;
}
.line-sp{
    margin-top: 0;
}
.img-ver{
    height:  30em;
    width:  30em;
    background-size: cover;
    box-shadow: 1px 1px 30px black;
}
.gauchetext{
    margin-right: 2em;
}
@media (max-width:1036px) {
    .gauchetext{
        margin-right: 0;
    }
}
#end{
    margin-bottom: 4em;
}

.examples{
    height: 50px;
    width: 150px;
    background-color: rgba(0, 0, 0, 0.799);
    transition: .3s all ease-in-out;
    color: white;
    border: none;
    box-shadow: 1px 1px 20px black;
    margin-top: 2em;
}
.examples:hover{
    height: 50px;
    width: 150px;
    background-color: black;
    color: white;
    border: none;
}



@media (max-width:1276px) {
    .expli-per{
    
        
        
        transform: translateY(-6em);
        
    
    }
    .expli-ver{
    
        
        
        transform: translateY(-6em);
        
    
    }
}

@media (max-width: 1036px) {
    .expli-per {
        padding: 0;
        margin-right: 3%;
    }
}

@media (max-width:1024px) {
    .expli-per-img{
        display: inline-block;
        width: 50vw;
    }
    .img-ver{
        display: inline-block;
        width: 50vw;
    }
    .expli-per{
    
        
        
        transform: translateY(-2em);
        
    
    }
    .expli-ver{
    
        
        
        transform: translateY(-2em);
        
    
    }
    h1{
        margin-top: 0%;
        width: 100%;
    }
}
@media (max-width:965px) {
    .expli-per-img{
        display: inline-block;
        width: 50vw;
    }
    .img-ver{
        display: inline-block;
        width: 50vw;
    }
    .expli-per{
    
        
        
        transform: translateY(0em);
        
    
    }
    .expli-ver{
    
        
        
        transform: translateY(0em);
        
    
    }
}

@media (max-width:910px) {
    .h1-produit{
        margin-top: 2%;
        font-size: 3rem;
    }
    .expli-per-img{
        display: inline-block;
        width: 50vw;
    }
    .img-ver{
        display: inline-block;
        width: 50vw;
    }
    .expli-per{
    
        
        
        transform: translateY(0em);
        
    
    }
    .expli-ver{
    
        
        
        transform: translateY(0em);
        
    
    }
}
@media (max-width:843px) {
    .grosse{
        height: fit-content;
    }
    .maconne{
        height: fit-content;
    }
    p{
        font-size: 1.1rem;
    }
    
    .h1-produit{
        
        margin-top: 2%;
        font-size: 3rem;
    }
    .expli-per-img{
        display: inline-block;
        width: 50vw;
        transform: translateY(-5em);
    }
    .img-ver{
        display: inline-block;
        width: 50vw;
        transform: translateY(-5em);
    }
    .expli-per{
    
        
        
        transform: translateY(1em);
        
    
    }
    .expli-ver{
    
        
        
        transform: translateY(1em);
        
    
    }
}


@media (max-width:738px) {
    .grosse{
        height: fit-content;
    }
    .maconne{
        height: fit-content;
    }
    p{
        font-size: 1rem;
    }
   
    .h1-produit{
        margin-top: 8%;
        font-size: 3rem;
    }
    .expli-per-img{
        display: inline-block;
        width: 50vw;
        box-shadow: 1px 1px 10px black;
        transform: translateY(-5em);
    }
    .img-ver{
        display: inline-block;
        width: 50vw;
        box-shadow: 1px 1px 10px black;
        transform: translateY(-5em);
    }
    .expli-per{
    
        
        
        transform: translateY(1em);
        
    
    }
    .expli-ver{
    
        
        
        transform: translateY(1em);
        
    
    }
}


@media (max-width:671px) {
    .grosse{
        height: fit-content;
    }
    .maconne{
        height: fit-content;
    }
    p{
        font-size: .9rem;
    }

    .h1-produit{
        margin-top: 6%;
        font-size: 2.5rem;
    }
    .expli-per-img{
        display: inline-block;
        width: 50vw;
        box-shadow: 1px 1px 10px black;
        transform: translateY(-5em);

    }
    .img-ver{
        display: inline-block;
        width: 50vw;
        box-shadow: 1px 1px 10px black;
        transform: translateY(-5em);

    }
    .expli-per{
    
        
        
        transform: translateY(1em);
        
    
    }
    .expli-ver{
    
        
        
        transform: translateY(1em);
        
    
    }
}



.footerprod{
    margin-top: 300px;
}
@media (max-width:965px) {
    .footerprod{
        margin-top: 500px;
    }
    .expli-per{
        border-radius: 1em;
    }
    .grosse{
        padding-bottom: 1em;
    }
    .filter{
        border-radius: 1em;
    }
    .grosse{
        height: fit-content;
    }
    .maconne{
        height: fit-content;
    }
    p{
        font-size: .9rem;
        
    }
  
    .h1-produit{
        margin-top: 6%;
        font-size: 2.5rem;
    }
    .expli-per-img{

        display: none;
        width: 50vw;
        box-shadow: 1px 1px 10px black;
    }
    .img-ver{
        display:none;
        width: 50vw;
        box-shadow: 1px 1px 10px black;
    }
    .maconne{
        margin: 0;
        padding: 0;
    }
    .expli-per{
    
        background-size: cover;
        
        width: 90vw;
        transform: translateY(-1em);
        padding: 0;
        margin: 0;
        padding: none;
        text-align: center;
        color: rgb(255, 255, 255);
        box-shadow: 1px 1px 30px black;
        background-size: cover;
    
    }

    .verandas_expli{
        background: url('/public/ver-per.jpg') ;
    }

    .grosoeuvres_expli {
        background: url('/public/maconnerietoit.jpg');
    }

    .chassis_expli {
        background: url('/public/chassis2.png');
    }

    .volets_expli {
        background: url('/public/volet.jpg');
    }

    .pergolas_expli {
        background: url('/public/pergola_travail.jpg');
    }

    .extensions_expli {
        background: url('/public/1728987218628.jpg');
    }

    .renovations_expli {
        background: url('/public/1728987218736.jpg');
    }

    .filter{
        background-color: rgba(0, 0, 0, 0.593);
        
        padding-top: 1em;
        width: 90vw;
        height: 100%;
        padding-bottom: 1em;
    }
    .expli-ver{
    
        
        
    background: url('/public/background.png') ;
        background-position: 10%;
        background-size: 90%;
        width: 90vw;
        transform: translateY(-1em);
        padding: 0;
        margin: 0;
        padding: none;
        text-align: center;
        color: white;
        box-shadow: 1px 1px 30px black;
        
    
    }
    .ex{
        background: url('/public/background.png') ;
    }
}
@media (max-width: 491px) {

    .h1-produit{
        margin-top: 4%;
        font-size: 2rem;
    }
}

@media (max-width: 473px) {

    .h1-produit{
        margin-top: 4%;
        font-size: 2rem;
    }
    .expli-ver{
        background-size: 150%;
    }
}
@media (max-width: 435px) {

    .background h1{
        margin-top: 4%;
        font-size: 10vw;
    }
    .expli-ver{
        background-size: 150%;
    }
}
@media (max-width: 400px) {
   
    .h1-produit{
        margin-top: 4%;
    }
    .expli-ver{
        background-size: 150%;
    }
}

@media (max-width: 375px) {

    .h1-produit{
        margin-top: 4%;
    }
    .expli-ver{
        background-size: 150%;
    }
}
@media (max-width: 375px) {
  
    .h1-produit{
        margin-top: 4%;
    }
    .expli-ver{
        background-size: 150%;
    }
}

@media (max-width:623px) {
    .backgroundprod h1{
        font-size: 3rem;
    }
}
@media (max-width:503px) {
    .backgroundprod h1{
        font-size: 2rem;
    }
}
@media (max-width:477px) {
    .footerprod{
        margin-top: 500px;
    }
}